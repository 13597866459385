@import "main.css";
.websitePageTitle-div {
display : none !important;
}

#navigation-bar-hide {
opacity: 1 !important;
top: 0 !important;
}

body > main > div.wrapper > div > div.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles {
margin-left: 60px;
}

.bandeau h1 {
color: black !important;
}

/* ---------------------------------------------------------------------------------------------------------------Responsive taille du logo sur bannière haute*/

@media screen and (min-width: 819px) {
.fondDegradeLogo {
    width: 260px;
}
}

@media screen and (max-width: 818px) {
.fondDegradeLogo {
    width: 120px;
}
}

/*------------------------------------------------------------------------------------------------ CTA Tarifs*/

.video-background-holder + .anchors + .cta_banner {
box-shadow: none !important;
}

#carouselWithFeatures + .anchors + .cta_banner {
box-shadow: none !important;
}

.cta_banner {
box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

/*------------------------------------------------------------------------------------------------ Bloc Contact*/

#contact p {
font-size: 24px !important;
}

/*------------------------------------------------------------------------------------------------ Bloc divider */

.b-bloc-divider {
    height: 110px !important;
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
}

/* ---------------------------------------------------------------------------Bouton image en bas à droite*/

/* Style normal*/
.btnDevis {
position: fixed;
bottom: 20px; right: 20px;
z-index: 10;
width: 130px;
}

/* Style HOVER*/
.btnDevis:hover {
width: 140px;
transition: all 0.5s ease-out;
}



/* -------------------------------------------------------------------------------------------------------Bannière vidéo*/

.videoTitle {
    width: 60% !important;
    margin-left: 0 !important;
}

.titleText h1 {
text-align: left !important;
}

#fullHeight > div.video-background-content.container.h-100 > div > div.container.videoTitle > div {
margin-top: 50px !important;
}


/* -------------------------------------------------------------------------------------------------------Logos devant titres */
/* -----------------------------------------------------------------------------------------Accueil*/
body > main > div.wrapper > div > div.container.my-5.col-lg-11.position-relative > 
div.row.p-4.pb-0.pe-lg-0.pt-lg-5.pb-lg-5.rounded-3.shadow-lg.justify-content-center.position-relative > 
div.display-5.fw-bold.p-4.pt-lg-2.titles > h2 > strong > span::before {
  
  content: '';
  display: inline-block;
  width: 80px; /* Largeur du logo */
  height: 80px; /* Hauteur du logo */
  background-image: url('https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FoQ52YgZu9fajCHqpI7VyoS5Pg4G3%2Fimages%2FH_accueil_ovfh.webp');
  background-size: contain; /* Adapte l'image à la taille */
  background-repeat: no-repeat;
  margin-right: 8px; /* Espacement entre le logo et le texte */
  vertical-align: middle; /* Aligne verticalement avec le texte */
}

/* -----------------------------------------------------------------------------------------Pages secondaires*/
body > main > div.wrapper > div > div:nth-child(7) > div:nth-child(2) > div.row.flex-lg-row-reverse.align-items-center.g-5.py-2.px-4.mt-5.position-relative.z-1 > div.col-lg-7.my-0.order-1.order-lg-1.order-xl-1.order-xxl-1 > div.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles > h2 > strong > span::before {
  
  content: '';
  display: inline-block;
  width: 80px; /* Largeur du logo */
  height: 80px; /* Hauteur du logo */
  background-image: url('https://firebasestorage.googleapis.com/v0/b/refpro-app-ca244.appspot.com/o/customers%2FoQ52YgZu9fajCHqpI7VyoS5Pg4G3%2Fimages%2Fh2_vitres_2_4t0j.webp?alt=media&token=53c6ac13-7f96-41a9-a931-875e6cec08ef');
  background-size: contain; /* Adapte l'image à la taille */
  background-repeat: no-repeat;
  margin-right: 8px; /* Espacement entre le logo et le texte */
  vertical-align: middle; /* Aligne verticalement avec le texte */
}

body > main > div.wrapper > div > div:nth-child(7) > div:nth-child(4) > div.row.flex-lg-row.align-items-center.g-5.py-2.px-4.mt-5.position-relative.z-1 > div.col-lg-7.my-0.order-1.order-lg-1.order-xl-1.order-xxl-1 > div.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles > h2 > strong > span::before {
  content: '';
  display: inline-block;
  width: 80px; /* Largeur du logo */
  height: 80px; /* Hauteur du logo */
  background-image: url('https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FoQ52YgZu9fajCHqpI7VyoS5Pg4G3%2Fimages%2FH_accueil_ovfh.webp');
  background-size: contain; /* Adapte l'image à la taille */
  background-repeat: no-repeat;
  margin-right: 8px; /* Espacement entre le logo et le texte */
  vertical-align: middle; /* Aligne verticalement avec le texte */
}

body > main > div.wrapper > div > div:nth-child(7) > div:nth-child(4) > div.row.flex-lg-row.align-items-center.g-5.py-2.px-4.mt-5.position-relative.z-1 > div.col-lg-7.my-0.order-1.order-lg-1.order-xl-1.order-xxl-1 > div.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles > h2 > span::before {
content: '';
  display: inline-block;
  width: 80px; /* Largeur du logo */
  height: 80px; /* Hauteur du logo */
  background-image: url('https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FoQ52YgZu9fajCHqpI7VyoS5Pg4G3%2Fimages%2FH_accueil_ovfh.webp');
  background-size: contain; /* Adapte l'image à la taille */
  background-repeat: no-repeat;
  margin-right: 8px; /* Espacement entre le logo et le texte */
  vertical-align: middle; /* Aligne verticalement avec le texte */
}

body > main > div.wrapper > div > div:nth-child(7) > div:nth-child(6) > div.row.flex-lg-row-reverse.align-items-center.g-5.py-2.px-4.mt-5.position-relative.z-1 > div.col-lg-7.my-0.order-1.order-lg-1.order-xl-1.order-xxl-1 > div.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles > h2 > strong > span::before {
  content: '';
  display: inline-block;
  width: 80px; /* Largeur du logo */
  height: 80px; /* Hauteur du logo */
  background-image: url('https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FoQ52YgZu9fajCHqpI7VyoS5Pg4G3%2Fimages%2Fh2_vitres_3_w6d7.webp');
  background-size: contain; /* Adapte l'image à la taille */
  background-repeat: no-repeat;
  margin-right: 8px; /* Espacement entre le logo et le texte */
  vertical-align: middle; /* Aligne verticalement avec le texte */
}

body > main > div.wrapper > div > div:nth-child(7) > div:nth-child(6) > div.row.flex-lg-row-reverse.align-items-center.g-5.py-2.px-4.mt-5.position-relative.z-1 > div.col-lg-7.my-0.order-1.order-lg-1.order-xl-1.order-xxl-1 > div.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles > h2 > span::before {
  
  content: '';
  display: inline-block;
  width: 80px; /* Largeur du logo */
  height: 80px; /* Hauteur du logo */
  background-image: url('https://storage.googleapis.com/refpro-app-ca244.appspot.com/customers%2FoQ52YgZu9fajCHqpI7VyoS5Pg4G3%2Fimages%2Fh2_vitres_3_w6d7.webp');
  background-size: contain; /* Adapte l'image à la taille */
  background-repeat: no-repeat;
  margin-right: 8px; /* Espacement entre le logo et le texte */
  vertical-align: middle; /* Aligne verticalement avec le texte */
}

/* -------------------------------------------------------------------------------------------------------Cartes prestations Accueil*/

@media (min-width: 768px) {
    .row-cols-md-3>* {
        width: 25% !important;
    }
}

div.cardsLinks-div div.background-image-div-opacity {
background-color: #EDEDED;
box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

div.card-content > div:nth-child(1) {
background-size: contain;
}

.card-content {
padding: 0 20px;
}

/* -------------------------------------------------------------------------------------------------------MAPS*/
.maps-section span  {
color: white !important;
}

/*---------------------------------------------------------------------------------------------------------Bouton RDV*/
.divBtnRDV {
  text-align : center;
}
/* Style normal*/

.btnRDV {
  font-size: 26px;
  padding: 10px 20px;
  background: #cd9fbc;
  color: white;
  text-decoration: none;
  border-radius : 20px;
}
/* Style HOVER*/
.btnRDV:hover {
  background: #ae427f;
color : white;
  transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
}
